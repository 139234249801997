import { defineStore } from 'pinia';
import { Schema, z } from 'zod';
import { computed, ref, watch } from 'vue';
import { cloneDeep, isEqual, difference, intersection, union } from 'lodash-es';
import { useDebounceFn, whenever } from '@vueuse/core';
import { StoreNames } from '../../../shared/store-names';
import { useAccountStore } from '../../../stores';
import { useUiStatesApi } from '../../ui-states';
import type { LocationsFilter } from '../interfaces';
import { Sorting } from '../../../shared/types';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  Description = 'description',
  Region = 'region',
  City = 'city',
  Brands = 'brands',
  Classifications = 'classifications',
  AtSite = 'at_site',
  InTransit = 'in_transit',
  Reserved = 'reserved',
  NumOfProducts = 'num_of_products',
  NumOfSkus = 'num_of_skus',
  InventoryCost = 'inventory_cost',
  InventoryPrice = 'inventory_price',
  AvoidReplenishment = 'avoid_replenishment',
  CreatedAt = 'created_at',
}

export const useInventoryLocationsPageStore = defineStore(StoreNames.InventoryLocationsPage, () => {
  const defaultColumnsVisibility: Record<string, boolean> = {
    [Columns.Name]: true,
    [Columns.ExternalId]: false,
    [Columns.LocationType]: true,
    [Columns.Description]: false,
    [Columns.Region]: false,
    [Columns.City]: true,
    [Columns.Brands]: true,
    [Columns.Classifications]: true,
    [Columns.AtSite]: true,
    [Columns.InTransit]: true,
    [Columns.Reserved]: true,
    [Columns.NumOfProducts]: false,
    [Columns.NumOfSkus]: false,
    [Columns.InventoryCost]: false,
    [Columns.InventoryPrice]: false,
    [Columns.AvoidReplenishment]: true,
    [Columns.CreatedAt]: false,
  };

  const defaultPinnedColumnsOrder: string[] = [Columns.Name, Columns.ExternalId];

  const defaultColumnsOrder: string[] = [
    Columns.LocationType,
    Columns.Description,
    Columns.Region,
    Columns.City,
    Columns.Brands,
    Columns.Classifications,
    Columns.AtSite,
    Columns.InTransit,
    Columns.Reserved,
    Columns.NumOfProducts,
    Columns.NumOfSkus,
    Columns.InventoryCost,
    Columns.InventoryPrice,
    Columns.AvoidReplenishment,
    Columns.CreatedAt,
  ];

  const defaultSorting: Sorting = {
    sortBy: null,
    sortOrder: 'asc',
  };

  const defaultAppliedFilters: LocationsFilter = {
    location_type: [],
    region: [],
    city: [],
    brands: [],
    classifications: [],
    avoid_replenishment: null,
  };

  const columnsVisibility = ref<Record<string, boolean>>({ ...defaultColumnsVisibility });
  const pinnedColumnsOrder = ref<string[]>([...defaultPinnedColumnsOrder]);
  const columnsOrder = ref<string[]>([...defaultColumnsOrder]);
  const sorting = ref<Sorting>({ ...defaultSorting });
  const appliedFilters = ref<LocationsFilter>(cloneDeep(defaultAppliedFilters));

  const visibleColumns = computed(() => {
    return Object.entries(columnsVisibility.value).reduce<string[]>((acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }

      return acc;
    }, []);
  });

  let currentDefaultColumnsVisibility = { ...defaultColumnsVisibility };
  let currentDefaultColumnsOrder = [...defaultColumnsOrder];

  function resetColumnsSettings() {
    columnsVisibility.value = {
      ...currentDefaultColumnsVisibility,
    };
    columnsOrder.value = [...currentDefaultColumnsOrder];
    pinnedColumnsOrder.value = [...defaultPinnedColumnsOrder];
  }

  function reset() {
    resetColumnsSettings();
    sorting.value = { ...defaultSorting };
    appliedFilters.value = cloneDeep(defaultAppliedFilters);
  }

  const api = useUiStatesApi();
  const accountStore = useAccountStore();

  const fetching = ref(false);
  const fetched = ref(false);

  let fetchingPromise: Promise<void> | null = null;

  async function fetch(force = false) {
    if (fetched.value && !force) {
      return fetchingPromise ?? Promise.resolve();
    }

    if (!fetchingPromise) {
      fetchingPromise = (async () => {
        fetching.value = true;

        const [persistedState] = await Promise.all([
          api
            .getUiStates({ key: StoreNames.InventoryLocationsPage })
            .then(({ data }) => data.data[0]),
          accountStore.fetch(),
        ]);

        // We use "current" defaults here with added custom columns and do not modify "default" defaults
        // because on next fetch custom data schema might be changed.
        currentDefaultColumnsVisibility = { ...defaultColumnsVisibility };
        currentDefaultColumnsOrder = [...defaultColumnsOrder];

        const customColumns =
          accountStore.customDataSchema.Inventory?.map(({ key }) => `custom_data.${key}`) ?? [];

        if (customColumns.length) {
          customColumns.forEach((item) => {
            currentDefaultColumnsVisibility[item] = true;
          });

          currentDefaultColumnsOrder.push(...customColumns);
        }

        reset();

        // Apply persisted state

        if (persistedState) {
          const allColumns = [...defaultPinnedColumnsOrder, ...currentDefaultColumnsOrder];

          if (persistedState.value.columnsVisibility) {
            try {
              columnsVisibility.value = z
                .object(
                  Object.keys(currentDefaultColumnsVisibility).reduce<Record<string, Schema>>(
                    (acc, key) => {
                      acc[key] = z.boolean().catch(currentDefaultColumnsVisibility[key]);
                      return acc;
                    },
                    {},
                  ),
                )
                .parse(persistedState.value.columnsVisibility);
            } catch (error) {
              // do nothing
            }
          }

          if (persistedState.value.pinnedColumnsOrder) {
            try {
              z.array(z.string()).parse(persistedState.value.pinnedColumnsOrder);

              pinnedColumnsOrder.value = intersection(
                persistedState.value.pinnedColumnsOrder,
                allColumns,
              );
            } catch (error) {
              // do nothing
            }
          }

          // Remove all pinned columns from columns list (in case of collision)
          columnsOrder.value = difference(allColumns, pinnedColumnsOrder.value);

          if (persistedState.value.columnsOrder) {
            try {
              z.array(z.string()).parse(persistedState.value.columnsOrder);

              columnsOrder.value = union(
                intersection(persistedState.value.columnsOrder, columnsOrder.value),
                columnsOrder.value,
              );
            } catch (error) {
              // do nothing
            }
          }

          if (persistedState.value.sorting) {
            try {
              sorting.value = z
                .object({
                  sortBy: z
                    .string()
                    .nullable()
                    .refine((value) => {
                      return value === null || allColumns.includes(value);
                    }),
                  sortOrder: z.enum(['asc', 'desc']),
                })
                .catch({ ...defaultSorting })
                .parse(persistedState.value.sorting);
            } catch (error) {
              // do nothing
            }
          }

          if (persistedState.value.appliedFilters) {
            try {
              appliedFilters.value = cloneDeep(
                z
                  .object(
                    Object.entries(defaultAppliedFilters).reduce<Record<string, Schema>>(
                      (acc, [key, value]) => {
                        // Multi select
                        if (Array.isArray(value)) {
                          acc[key] = z
                            .array(
                              z.string().or(z.object({ value: z.string(), label: z.string() })),
                            )
                            .catch(value as string[]);
                          return acc;
                        }

                        // Flag
                        if (value === null || typeof value === 'boolean') {
                          acc[key] = z.boolean().nullable().catch(value);
                          return acc;
                        }

                        return acc;
                      },
                      {},
                    ),
                  )
                  .parse(persistedState.value.appliedFilters) as LocationsFilter,
              );
            } catch (error) {
              // do nothing
            }
          }
        }

        fetching.value = false;
        fetched.value = true;
        fetchingPromise = null;
      })();
    }

    return fetchingPromise;
  }

  async function persist() {
    await api.saveUiState({
      key: StoreNames.InventoryLocationsPage,
      value: {
        columnsVisibility: columnsVisibility.value,
        columnsOrder: columnsOrder.value,
        pinnedColumnsOrder: pinnedColumnsOrder.value,
        sorting: sorting.value,
        appliedFilters: appliedFilters.value,
      },
    });
  }

  const debouncedPersist = useDebounceFn(persist, 3000);
  const autoPersistEnabled = ref(false);

  const persistingValue = computed(() => ({
    columnsVisibility: { ...columnsVisibility.value },
    columnsOrder: [...columnsOrder.value],
    pinnedColumnsOrder: [...pinnedColumnsOrder.value],
    sorting: { ...sorting.value },
    appliedFilters: { ...appliedFilters.value },
  }));

  watch(
    persistingValue,
    (value, oldValue) => {
      if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
        debouncedPersist();
      }
    },
    {
      deep: true,
    },
  );

  whenever(
    fetched,
    () => {
      autoPersistEnabled.value = true;
    },
    { once: true },
  );

  return {
    fetching,
    fetched,
    fetch,
    reset,
    resetColumnsSettings,
    columnsVisibility,
    visibleColumns,
    columnsOrder,
    pinnedColumnsOrder,
    sorting,
    appliedFilters,
  };
});
