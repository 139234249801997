import { defineStore } from 'pinia';
import { Schema, z } from 'zod';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { cloneDeep, difference, intersection, isEqual, union } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';
import type { ReplenishmentLocationsFilter } from '../interfaces';
import { Sorting } from '../../../shared/types';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  City = 'city',
  Sold = 'sold',
  Coverage = 'coverage',
  Replenishment = 'replenishment',
  ExpectedCoverage = 'expected_coverage',
  AvgDailySales = 'avg_daily_sales',
  SourceLocations = 'source_locations',
  Region = 'region',
  TotalProducts = 'total_products',
  TotalSkus = 'total_skus',
  ReplenishmentTime = 'replenishment_time',
  Classifications = 'classifications',
  Brands = 'brands',
  SoldDepleted = 'sold_depleted',
  CoverageDepleted = 'coverage_depleted',
  AvgDailySalesDepleted = 'avg_daily_sales_depleted',
  Constraints = 'constraints',
}

export const useReplenishmentLocationsPageStore = defineStore(
  StoreNames.ReplenishmentLocationsPage,
  () => {
    const defaultColumnsVisibility: Record<string, boolean> = {
      [Columns.Name]: true,
      [Columns.ExternalId]: false,
      [Columns.LocationType]: false,
      [Columns.City]: false,
      [Columns.Sold]: true,
      [Columns.Coverage]: false,
      [Columns.Replenishment]: true,
      [Columns.ExpectedCoverage]: false,
      [Columns.AvgDailySales]: false,
      [Columns.SourceLocations]: false,
      [Columns.Region]: false,
      [Columns.TotalProducts]: true,
      [Columns.TotalSkus]: true,
      [Columns.ReplenishmentTime]: false,
      [Columns.Classifications]: false,
      [Columns.Brands]: false,
      [Columns.SoldDepleted]: false,
      [Columns.CoverageDepleted]: false,
      [Columns.AvgDailySalesDepleted]: false,
      [Columns.Constraints]: true,
    };

    const defaultPinnedColumnsOrder: string[] = [Columns.Name, Columns.ExternalId];

    const defaultColumnsOrder: string[] = [
      Columns.LocationType,
      Columns.City,
      Columns.Sold,
      Columns.Coverage,
      Columns.Replenishment,
      Columns.ExpectedCoverage,
      Columns.AvgDailySales,
      Columns.SourceLocations,
      Columns.Region,
      Columns.TotalProducts,
      Columns.TotalSkus,
      Columns.ReplenishmentTime,
      Columns.Classifications,
      Columns.Brands,
      Columns.SoldDepleted,
      Columns.CoverageDepleted,
      Columns.AvgDailySalesDepleted,
      Columns.Constraints,
    ];

    const defaultSorting: Sorting = {
      sortBy: null,
      sortOrder: 'asc',
    };

    const defaultAppliedFilters: ReplenishmentLocationsFilter = {
      region: [],
      city: [],
    };

    const columnsVisibility = ref<Record<string, boolean>>({ ...defaultColumnsVisibility });
    const pinnedColumnsOrder = ref<string[]>([...defaultPinnedColumnsOrder]);
    const columnsOrder = ref<string[]>([...defaultColumnsOrder]);
    const sorting = ref<Sorting>({ ...defaultSorting });
    const appliedFilters = ref<ReplenishmentLocationsFilter>(cloneDeep(defaultAppliedFilters));

    const visibleColumns = computed(() => {
      return Object.entries(columnsVisibility.value).reduce<string[]>((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }

        return acc;
      }, []);
    });

    function resetColumnsSettings() {
      columnsVisibility.value = {
        ...defaultColumnsVisibility,
      };
      columnsOrder.value = [...defaultColumnsOrder];
      pinnedColumnsOrder.value = [...defaultPinnedColumnsOrder];
    }

    function reset() {
      resetColumnsSettings();
      sorting.value = { ...defaultSorting };
      appliedFilters.value = cloneDeep(defaultAppliedFilters);
    }

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const persistedState = await api
            .getUiStates({ key: StoreNames.ReplenishmentLocationsPage })
            .then(({ data }) => data.data[0]);

          reset();

          // Apply persisted state

          if (persistedState) {
            const allColumns = [...defaultPinnedColumnsOrder, ...defaultColumnsOrder];

            if (persistedState.value.columnsVisibility) {
              try {
                columnsVisibility.value = z
                  .object(
                    Object.keys(defaultColumnsVisibility).reduce<Record<string, Schema>>(
                      (acc, key) => {
                        acc[key] = z.boolean().catch(defaultColumnsVisibility[key]);
                        return acc;
                      },
                      {},
                    ),
                  )
                  .parse(persistedState.value.columnsVisibility);
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.pinnedColumnsOrder) {
              try {
                z.array(z.string()).parse(persistedState.value.pinnedColumnsOrder);

                pinnedColumnsOrder.value = intersection(
                  persistedState.value.pinnedColumnsOrder,
                  allColumns,
                );
              } catch (error) {
                // do nothing
              }
            }

            // Remove all pinned columns from columns list (in case of collision)
            columnsOrder.value = difference(allColumns, pinnedColumnsOrder.value);

            if (persistedState.value.columnsOrder) {
              try {
                z.array(z.string()).parse(persistedState.value.columnsOrder);

                columnsOrder.value = union(
                  intersection(persistedState.value.columnsOrder, columnsOrder.value),
                  columnsOrder.value,
                );
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.sorting) {
              try {
                sorting.value = z
                  .object({
                    sortBy: z
                      .string()
                      .nullable()
                      .refine((value) => {
                        return value === null || allColumns.includes(value);
                      }),
                    sortOrder: z.enum(['asc', 'desc']),
                  })
                  .catch({ ...defaultSorting })
                  .parse(persistedState.value.sorting);
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.appliedFilters) {
              try {
                appliedFilters.value = cloneDeep(
                  z
                    .object(
                      Object.entries(defaultAppliedFilters).reduce<Record<string, Schema>>(
                        (acc, [key, value]) => {
                          // Multi select
                          if (Array.isArray(value)) {
                            acc[key] = z
                              .array(
                                z.string().or(z.object({ value: z.string(), label: z.string() })),
                              )
                              .catch(value as string[]);
                            return acc;
                          }

                          // Flag
                          if (value === null || typeof value === 'boolean') {
                            acc[key] = z.boolean().nullable().catch(value);
                            return acc;
                          }

                          return acc;
                        },
                        {},
                      ),
                    )
                    .parse(persistedState.value.appliedFilters) as ReplenishmentLocationsFilter,
                );
              } catch (error) {
                // do nothing
              }
            }
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentLocationsPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          pinnedColumnsOrder: pinnedColumnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      pinnedColumnsOrder: [...pinnedColumnsOrder.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      reset,
      resetColumnsSettings,
      columnsVisibility,
      visibleColumns,
      columnsOrder,
      pinnedColumnsOrder,
      sorting,
      appliedFilters,
    };
  },
);
