import { defineStore } from 'pinia';
import { Schema, z } from 'zod';
import { computed, ref, watch } from 'vue';
import { cloneDeep, difference, intersection, isEqual, union } from 'lodash-es';
import { useDebounceFn, whenever } from '@vueuse/core';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';
import type { ReplenishmentInventoriesFilter } from '../interfaces';
import { Sorting } from '../../../shared/types';

enum Columns {
  SkuChart = 'sku_chart',
  Location = 'location_name',
  LocationExternalId = 'location_external_id',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  SkuDescription = 'sku_description',
  Product = 'product',
  ProductExternalId = 'product_external_id',
  Style = 'style_names',
  Size = 'size_names',
  Color = 'color_names',
  Category = 'category_names',
  AG = 'assortment_group',
  Department = 'department',
  DepartmentId = 'department_id',
  UnitPrice = 'unit_price',
  Brand = 'brand_names',
  Season = 'season_names',
  TargetMarket = 'target_market_names',
  Replenishment = 'replenishment',
  ReplenishmentTime = 'replenishment_time',
  ShipmentDays = 'shipment_days',
  OptimalStock = 'optimal_stock',
  OptimalStockVariance = 'optimal_stock_variance',
  OptimalStockVariancePercentage = 'optimal_stock_variance_percentage',
  InventoryNeeded = 'inventory_needed',
  Stock = 'stock',
  WhInventory = 'wh_inventory',
  Sold = 'sold',
  SaleRate = 'sale_rate',
  Coverage = 'coverage',
  ReplenishmentGap = 'replenishment_gap',
  Constraints = 'constraints',
  SpecialEvent = 'special_event_names',
  DaysSinceAllocation = 'days_since_allocation',
}

export const useReplenishmentInventoriesPageStore = defineStore(
  StoreNames.ReplenishmentInventoriesPage,
  () => {
    const defaultColumnsVisibility: Record<string, boolean> = {
      [Columns.SkuName]: true,
      [Columns.SkuExternalId]: false,
      [Columns.Location]: true,
      [Columns.LocationExternalId]: false,
      [Columns.SkuDescription]: false,
      [Columns.Product]: false,
      [Columns.ProductExternalId]: false,
      [Columns.Style]: false,
      [Columns.Size]: false,
      [Columns.Color]: false,
      [Columns.Category]: false,
      [Columns.AG]: false,
      [Columns.Department]: false,
      [Columns.DepartmentId]: false,
      [Columns.UnitPrice]: false,
      [Columns.Brand]: false,
      [Columns.Season]: false,
      [Columns.TargetMarket]: false,
      [Columns.Replenishment]: true,
      [Columns.ReplenishmentTime]: false,
      [Columns.ShipmentDays]: false,
      [Columns.OptimalStock]: true,
      [Columns.OptimalStockVariance]: false,
      [Columns.OptimalStockVariancePercentage]: false,
      [Columns.InventoryNeeded]: true,
      [Columns.Stock]: true,
      [Columns.WhInventory]: true,
      [Columns.Sold]: false,
      [Columns.SaleRate]: true,
      [Columns.Coverage]: false,
      [Columns.ReplenishmentGap]: true,
      [Columns.Constraints]: true,
      [Columns.SpecialEvent]: false,
      [Columns.DaysSinceAllocation]: false,
    };

    const defaultPinnedColumnsOrder: string[] = [
      Columns.Location,
      Columns.LocationExternalId,
      Columns.SkuName,
      Columns.SkuExternalId,
    ];

    const defaultColumnsOrder: string[] = [
      Columns.SkuChart,
      Columns.SkuDescription,
      Columns.Product,
      Columns.ProductExternalId,
      Columns.Style,
      Columns.Size,
      Columns.Color,
      Columns.Category,
      Columns.AG,
      Columns.Department,
      Columns.DepartmentId,
      Columns.UnitPrice,
      Columns.Brand,
      Columns.Season,
      Columns.TargetMarket,
      Columns.Replenishment,
      Columns.ReplenishmentTime,
      Columns.ShipmentDays,
      Columns.OptimalStock,
      Columns.OptimalStockVariance,
      Columns.OptimalStockVariancePercentage,
      Columns.InventoryNeeded,
      Columns.Stock,
      Columns.WhInventory,
      Columns.Sold,
      Columns.SaleRate,
      Columns.Coverage,
      Columns.ReplenishmentGap,
      Columns.Constraints,
      Columns.SpecialEvent,
      Columns.DaysSinceAllocation,
    ];

    const defaultSorting: Sorting = {
      sortBy: null,
      sortOrder: 'asc',
    };

    const defaultAppliedFilters: ReplenishmentInventoriesFilter = {
      location_external_id: [],
      sku_external_id: [],
      product_external_id: [],
      categories: [],
      brands: [],
      seasons: [],
      styles: [],
      department_id: [],
      replenishment: [null, null],
      optimal_stock: [null, null],
      optimal_stock_variance: [null, null],
      constraints: [],
      optimal_stock_variance_percentage: [null, null],
      special_event_names: [],
      days_since_allocation: [null, null],
    };

    const columnsVisibility = ref<Record<string, boolean>>({ ...defaultColumnsVisibility });
    const pinnedColumnsOrder = ref<string[]>([...defaultPinnedColumnsOrder]);
    const columnsOrder = ref<string[]>([...defaultColumnsOrder]);
    const sorting = ref<Sorting>({ ...defaultSorting });
    const appliedFilters = ref<ReplenishmentInventoriesFilter>(cloneDeep(defaultAppliedFilters));

    const visibleColumns = computed(() => {
      return Object.entries(columnsVisibility.value).reduce<string[]>((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }

        return acc;
      }, []);
    });

    function resetColumnsSettings() {
      columnsVisibility.value = {
        ...defaultColumnsVisibility,
      };
      columnsOrder.value = [...defaultColumnsOrder];
      pinnedColumnsOrder.value = [...defaultPinnedColumnsOrder];
    }

    function reset() {
      resetColumnsSettings();
      sorting.value = { ...defaultSorting };
      appliedFilters.value = cloneDeep(defaultAppliedFilters);
    }

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const persistedState = await api
            .getUiStates({ key: StoreNames.ReplenishmentInventoriesPage })
            .then(({ data }) => data.data[0]);

          reset();

          // Apply persisted state

          if (persistedState) {
            const allColumns = [...defaultPinnedColumnsOrder, ...defaultColumnsOrder];

            if (persistedState.value.columnsVisibility) {
              try {
                columnsVisibility.value = z
                  .object(
                    Object.keys(defaultColumnsVisibility).reduce<Record<string, Schema>>(
                      (acc, key) => {
                        acc[key] = z.boolean().catch(defaultColumnsVisibility[key]);
                        return acc;
                      },
                      {},
                    ),
                  )
                  .parse(persistedState.value.columnsVisibility);
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.pinnedColumnsOrder) {
              try {
                z.array(z.string()).parse(persistedState.value.pinnedColumnsOrder);

                pinnedColumnsOrder.value = intersection(
                  persistedState.value.pinnedColumnsOrder,
                  allColumns,
                );
              } catch (error) {
                // do nothing
              }
            }

            // Remove all pinned columns from columns list (in case of collision)
            columnsOrder.value = difference(allColumns, pinnedColumnsOrder.value);

            if (persistedState.value.columnsOrder) {
              try {
                z.array(z.string()).parse(persistedState.value.columnsOrder);

                columnsOrder.value = union(
                  intersection(persistedState.value.columnsOrder, columnsOrder.value),
                  columnsOrder.value,
                );
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.sorting) {
              try {
                sorting.value = z
                  .object({
                    sortBy: z
                      .string()
                      .nullable()
                      .refine((value) => {
                        return value === null || allColumns.includes(value);
                      }),
                    sortOrder: z.enum(['asc', 'desc']),
                  })
                  .catch({ ...defaultSorting })
                  .parse(persistedState.value.sorting);
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.appliedFilters) {
              try {
                appliedFilters.value = cloneDeep(
                  z
                    .object(
                      Object.entries(defaultAppliedFilters).reduce<Record<string, Schema>>(
                        (acc, [key, value]) => {
                          // Range
                          if (
                            Array.isArray(value) &&
                            value.length === 2 &&
                            value.every((item) => item === null || typeof item === 'number')
                          ) {
                            acc[key] = z
                              .tuple([z.number().nullable(), z.number().nullable()])
                              .catch(value);
                            return acc;
                          }

                          // Multi select
                          if (Array.isArray(value)) {
                            acc[key] = z
                              .array(
                                z.string().or(z.object({ value: z.string(), label: z.string() })),
                              )
                              .catch(value as string[]);
                            return acc;
                          }

                          // Flag
                          if (value === null || typeof value === 'boolean') {
                            acc[key] = z.boolean().nullable().catch(value);
                            return acc;
                          }

                          return acc;
                        },
                        {},
                      ),
                    )
                    .parse(persistedState.value.appliedFilters) as ReplenishmentInventoriesFilter,
                );
              } catch (error) {
                // do nothing
              }
            }
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentInventoriesPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          pinnedColumnsOrder: pinnedColumnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      pinnedColumnsOrder: [...pinnedColumnsOrder.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      reset,
      resetColumnsSettings,
      columnsVisibility,
      visibleColumns,
      columnsOrder,
      pinnedColumnsOrder,
      sorting,
      appliedFilters,
    };
  },
);
